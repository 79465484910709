export const useVerificationAlert = () => {
  const { t } = useI18n();

  const getVerificationAlert = () => ({
    INITIAL: {
      action: t('dictionaries.verification.INITIAL.action'),
      class: 'accentGradient',
      name: t('dictionaries.verification.INITIAL.name'),
      description: t('dictionaries.verification.INITIAL.description'),
    },
    REJECTED: {
      action: t('dictionaries.verification.REJECTED.action'),
      class: 'accentGradient',
      name: t('dictionaries.verification.REJECTED.name'),
      description: t('dictionaries.verification.REJECTED.description'),
    },
    APPROVED: {
      action: t('dictionaries.verification.APPROVED.action'),
      class: 'greenGradient',
      name: t('dictionaries.verification.APPROVED.name'),
      description: t('dictionaries.verification.APPROVED.description'),
    },
    WAITING: {
      action: t('dictionaries.verification.WAITING.action'),
      class: '',
      name: t('dictionaries.verification.WAITING.name'),
      description: t('dictionaries.verification.WAITING.description'),
    },
  });

  return {
    verificationAlert: getVerificationAlert(),
  }
};
