<script setup lang="ts">
  import { useVerificationAlert } from '@/utils';
  import { UserVerificationStatus } from '@/interfaces';

  const { verificationAlert } = useVerificationAlert()

  const localePath = useLocalePath();
  const userStore = useUserStore();

  const notification = useCookie('v-n');
  const currentStatus = computed(() => verificationAlert[userStore.getVerificationStatus]);
  const isWaitingVerification = computed(
    () =>
      userStore.getVerificationStatus === UserVerificationStatus.WAITING ||
      (userStore.getVerificationStatus === UserVerificationStatus.APPROVED && notification.value),
  );
  const isRejectedVerification = computed(
    () =>
      userStore.getVerificationStatus === UserVerificationStatus.REJECTED &&
      userStore.getVerificationComment,
  );

  const actionStatus = async () => {
    const status = userStore.getVerificationStatus;
    if (status === UserVerificationStatus.REJECTED) {
      await navigateTo(localePath({ name: Page.PROFILE_VERIFICATION }));
    } else {
      const notification = useCookie('v-n');
      notification.value = 'true';
    }
  };
</script>

<template>
  <div
    v-if="!isWaitingVerification"
    class="verification-alert flex flex-is"
    :class="currentStatus.class"
  >
    <div class="icon">
      <common-icon-common-info
        v-if="userStore.isApprovedVerification"
        class-mods="green"
        size="20px"
      ></common-icon-common-info>
      <common-icon-common-alert v-else class-mods="accent" size="20px"></common-icon-common-alert>
    </div>
    <div class="container">
      <div class="title">{{ currentStatus.name }}</div>
      <div class="description">
        {{ isRejectedVerification ? userStore.getVerificationComment : currentStatus.description }}
      </div>
    </div>
    <div v-if="currentStatus.action" class="action">
      <common-button
        :text="currentStatus.action"
        :class-mods="currentStatus.class"
        @click="actionStatus"
      ></common-button>
    </div>
  </div>
</template>

<style scoped lang="scss">
.verification-alert {
  border: 1px solid;
  @apply py-3 px-4 rounded-lg gap-3 border-accent text-sm text-accent;
  .container {
    .title {
      @apply font-medium;
    }
  }
  &.greenGradient {
    @apply border-green text-green;
  }
}
</style>
