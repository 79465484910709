<script setup lang="ts">
  const { t } = useI18n();

  const route = useRoute();
  const list = {
    profile: { title: t('pages.profile.header.profile'), description: t('pages.profile.header.otherUsersSee') },
    'profile-verification': { title: t('pages.profile.header.verification'), description: t('pages.profile.header.necessaryData') },
  };

  const currentRoute = computed(() => list[route.name as keyof typeof list]);
</script>

<template>
  <header class="user-header flex-col">
    <div class="title">{{ currentRoute?.title }}</div>
    <div class="description">{{ currentRoute?.description }}</div>
  </header>
</template>

<style scoped lang="scss">
header {
  border-bottom: 1px solid;
  @apply gap-2 pb-4 border-gray-4;
  .title {
    @apply text-2xl font-semibold;
  }
  .description {
    @apply text-gray-2;
  }
}
</style>
