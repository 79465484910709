<script setup lang="ts">
  const route = useRoute();
</script>

<template>
  <section class="profile">
    <common-page-header></common-page-header>
    <main class="profile-container">
      <header>
        <h1>{{ $t('pages.profile.header.profile') }}</h1>
        <h2>
          {{ $t('pages.profile.desc') }}
        </h2>
      </header>
      <main class="flex profile-main">
        <aside>
          <profile-navigation></profile-navigation>
        </aside>
        <section class="flex-col">
          <profile-verification-alert
            v-if="route.name.split('___')[0] === Page.PROFILE"
          ></profile-verification-alert>
          <profile-header></profile-header>
          <slot></slot>
        </section>
      </main>
    </main>
  </section>
</template>

<style lang="scss" scoped>
.profile {
  @apply padding-top-for-fixed-header;

  &-main {
    @apply flex flex-col md:block;
  }

  &-container {
    @apply px-[10%] py-8;
    header {
      @apply pb-6 border-separator border-b border-solid;

      h2 {
        @apply text-separator;
      }
    }

    main {
      @apply pt-8 gap-8;

      aside {
        @apply w-full md:w-1/5;
      }

      section {
        @apply w-full md:w-4/5 gap-6;
      }
    }
  }
}
</style>
