<script setup lang="ts">
  import { NavigationItem } from '@/interfaces';

  // import { navigationProfileList } from '@/utils/data/navigation';
  const { t } = useI18n();

  const navigationProfileList = computed<NavigationItem[]>(() => [
    { path: Page.PROFILE, name: t('components.common.header.information') },
    { path: Page.PROFILE_VERIFICATION, name: t('components.common.header.verification') },
  ]);


  const localePath = useLocalePath();
  const userStore = useUserStore();
</script>

<template>
  <nav>
    <ul class="flex-col">
      <li v-for="item of navigationProfileList" :key="item.path">
        <nuxt-link v-if="item.path" class="flex-c-b" active-class="active" :to="localePath(item.path!)">
          <span>{{ item.name }}</span>
          <common-icon-common-alert
            v-if="item.path === Page.PROFILE_VERIFICATION && !userStore.isApprovedVerification"
            class-mods="error"
          ></common-icon-common-alert>
        </nuxt-link>
      </li>
    </ul>
  </nav>
</template>

<style scoped lang="scss">
nav {
  ul {
    @apply gap-1;
    li {
      a {
        transition: 0.3s all;
        @apply py-2 px-3 rounded-md text-sm font-medium;

        &.active,
        &:hover {
          transition: 0.3s all;
          @apply bg-gray-4;
        }
      }
    }
  }
}
</style>
